body {
  overflow-x: hidden;
}

.input-group > :first-child {
  border-radius: 4px;
}

$hero-height: 45vh;
$hero-height-small: 21vh;

.orbit-container {
  max-height: $hero-height;
  .orbit-figure {
    height: $hero-height;
    &.s1 {
      background: url('../img/building01.jpg') 55% 70% no-repeat;
      background-size: cover;
    }
    &.s2 {
      background: url('../img/building02.jpg') 0 95% no-repeat;
      background-size: cover;
    }
    @include breakpoint(medium down) {
      height: $hero-height-small;
    }
  }
}

.hero {
  height: $hero-height;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  // &.main {
  //   background: url('../img/hero.png') 50% no-repeat;
  //   background-size: contain;
  //   margin: 1em 0;
  // }
  &.vibe {
    background: url('../img/dogs.jpg') 50% no-repeat;
    background-size: cover;
  }
  &.teamh {
    background: url('../img/team.jpg') 0 30% no-repeat;
    background-size: cover;
  }
  &.servicesh {
    background: url('../img/services.jpg') 0 45% no-repeat;
    background-size: cover;
  }
  &.contactush {
    background: url('../img/contactus.jpg') 0 45% no-repeat;
    background-size: cover;
  }
  a {
    width: 100%;
    height: 100%;
    display: block;
  }
  @include breakpoint(medium down) {
    height: $hero-height-small;
  }
}

.menu {
  a {
    color: $white;
    &:hover {
      color: $white;
      text-decoration: underline;
    }
    &:focus {
      color: $white;
    }
  }
  .menu-text, .title-bar-title {
    color: $white;
    font-weight: normal;
    a {
      color: $white;
      display: inline-block;
      padding: 0;
      &:hover, &:focus {
        color: $white;
        text-decoration: none;
      }
    }
  }
}

.top-bar {
  min-height: 77px;
}

.top-bar-left {
  .title-bar-title {
    color: $white;
    font-weight: normal;
    a {
      color: $white;
      display: block;
      padding-left: 1rem;
      text-align: center;
      &:hover, &:focus {
        color: $white;
        text-decoration: none;
      }
    }
  }
}

.title-bar-left {
  a {
    color: $white;
    display: inline-block;
    &:hover, &:focus {
      color: $white;
      text-decoration: none;
    }
    @media only screen and (max-width: 369px) {  // account for galaxy s5 and iPhone 5 and lower
      font-size: 0.8em;
    }
  }
}

.top-bar-center {
  // -webkit-flex: 1 0 auto;
  //     -ms-flex: 1 0 auto;
  //         flex: 1 0 auto;
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translate(-50%, 0);
  color: $white;
  a {
    display: block;
    text-align: center;
    color: $white;
    &:hover, &:focus {
      color: $white;
      text-decoration: none;
    }
  }
  @include breakpoint(medium down) {
    display: none;
  }
}


.title-bar-right {
  a {
    color: $white;
    &:hover {
      color: $white;
      text-decoration: underline;
    }
    &:focus {
      color: $white;
    }
    @media only screen and (max-width: 369px) { // account for galaxy s5 and iPhone 5 and lower
      font-size: 0.8em;
    }
  }
}

// #map {
//   width: 100%;
//   height: $hero-height;
//   background-color: $light-gray;
// }

.grid-container {
  &.care, &.services, &.team {
    background-color: $green;
    color: $white; 
    .cell {
      padding: 2em 0;
    }
    a {
      font-size: rem-calc(20);
      color: $white;
      &:hover {
        color: $white;
        border-bottom: 1px solid $white;
      }
      &:focus {
        color: $white;
      }
      &:after {
        position: absolute; /* Prevent underline of arrow */
        padding-left: rem-calc(5); /* Add a little space between text and arrow */
        content: "\00bb"; /* Unicode hex for &raquo; */
      }
    }
  }
  &.contact {
    padding: 2em 0;
    h3 {
      margin-bottom: 1rem;
    }
    h4 {
      text-decoration: underline;
      @include breakpoint(medium down) {
        padding-top: 1rem;
      }
      &.hours {
        @include breakpoint(medium down) {
          padding-top: 1.5rem;
        }
      }
    }
    h5 {
      a {
        color: $green;
        &:hover {
          border-bottom: 1px solid $green;
        }
      }
    }
    &.us {
      background-color: $green;
      color: $white; 
      h4 {
        text-decoration: none;
        &.hours {
          text-decoration: underline;
          padding-top: 1rem;
        }
        &.email {
          padding-top: 1rem;
        }
        a {
          color: $white;
          &:hover {
            border-bottom: 1px solid $white;
          }
        }
      }
    }
  }
  &.footer {
    background-color: $brown;
    color: $white;
    .copy {
      line-height: 32px;
      padding: 0.7rem 1rem;
    }
    .menu {
      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
    ul {
      &#social {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        @include breakpoint(small only) {
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
        }
      }
      @include breakpoint(small only) {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
    }
    li {
      padding: 0.7rem 1rem;
    }
    .social {
      width: 32px;
      height: 32px;
      span {
        display: none;
      }
      &.fb {
        background: url('../img/fb.png') no-repeat;
      }
      &.yp {
        background: url('../img/yp.png') no-repeat;
        width: 64px;
      }
      &.gp {
        background: url('../img/gp.png') no-repeat;
      }
    }
  }
  .team-card {
    &:first-child {
      margin-top: 1.25rem;  
    }
    h3 {
      margin: 0;
    }
    h4 {
      color: $green;
    }
    .thumbnail {
      box-shadow: none;
      border-color: $black;
    }  
  }
}

#preventative-services, #primary-services {
  padding-bottom: 2rem;
  h2 {
    font-weight: 600;
    color: $green;
  }
  .accordion {
    .accordion-title {
      border: 0;
      color: $black;
      font-size: rem-calc(24);
      font-weight: 600;
      line-height: rem-calc(29);
      padding: 0;
      padding: 1.5rem 4rem 1.5rem 3rem;
      &:hover, &:focus {
        background-color: $white;
      }
      &::before {
        top: 40%;
        content: '';
        border: solid black;
        border-top-width: medium;
        border-right-width: medium;
        border-bottom-width: medium;
        border-left-width: medium;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
      @include breakpoint(small only) {
        font-size: rem-calc(16);
        line-height: rem-calc(21);
        padding: 1.5rem 3rem 1.5rem 2rem;
      }
    }
    .accordion-content {
      border: 0;
      padding: 0 3rem 2rem;
      p {
        color: $dark-gray;
      }
      ul {
        margin-bottom: 1rem;
        li {
          color: $dark-gray;
        }
      }
      span {
        font-weight: 600;
      }
      @include breakpoint(small only) {
        padding: 0 3rem 2rem 2rem;
      }
    }
    .accordion-item {
      border-bottom: 1px solid $black;
      &.is-active {
        > .accordion-title {
          &::before {
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
          }
        }
      }
    }
  }
}

#preventative-services {
  h2 {
    margin-top: 2rem;
  }
}
.contact-form {
  /* Bootstrap */
  /* BootstrapValidator */
  /* Extras */
  padding: 3rem 0; 
  @include breakpoint(small only) {
    padding: 1rem 0;
  }
}
  .contact-form button,
  .contact-form input,
  .contact-form optgroup,
  .contact-form select,
  .contact-form textarea {
    margin: 0;
    font: inherit;
    color: inherit; }
  .contact-form button {
    overflow: visible; }
  .contact-form button,
  .contact-form select {
    text-transform: none; }
  .contact-form button,
  .contact-form html input[type="button"],
  .contact-form input[type="reset"],
  .contact-form input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer; }
  .contact-form button[disabled],
  .contact-form html input[disabled] {
    cursor: default; }
  .contact-form button::-moz-focus-inner,
  .contact-form input::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .contact-form input {
    line-height: normal; }
  .contact-form input[type="checkbox"],
  .contact-form input[type="radio"] {
    box-sizing: border-box;
    padding: 0; }
  .contact-form input[type="number"]::-webkit-inner-spin-button,
  .contact-form input[type="number"]::-webkit-outer-spin-button {
    height: auto; }
  .contact-form input[type="search"] {
    box-sizing: content-box;
    -webkit-appearance: textfield; }
  .contact-form input[type="search"]::-webkit-search-cancel-button,
  .contact-form input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }
  .contact-form fieldset {
    padding: .35em .625em .75em;
    margin: 0 2px;
    border: 1px solid #c0c0c0; }
  .contact-form legend {
    padding: 0;
    border: 0; }
  .contact-form textarea {
    overflow: auto; }
  .contact-form .row {
    margin-right: -15px;
    margin-left: -15px; }
  .contact-form .col-xs-1, .contact-form .col-sm-1, .contact-form .col-md-1, .contact-form .col-lg-1, .contact-form .col-xs-2, .contact-form .col-sm-2, .contact-form .col-md-2, .contact-form .col-lg-2, .contact-form .col-xs-3, .contact-form .col-sm-3, .contact-form .col-md-3, .contact-form .col-lg-3, .contact-form .col-xs-4, .contact-form .col-sm-4, .contact-form .col-md-4, .contact-form .col-lg-4, .contact-form .col-xs-5, .contact-form .col-sm-5, .contact-form .col-md-5, .contact-form .col-lg-5, .contact-form .col-xs-6, .contact-form .col-sm-6, .contact-form .col-md-6, .contact-form .col-lg-6, .contact-form .col-xs-7, .contact-form .col-sm-7, .contact-form .col-md-7, .contact-form .col-lg-7, .contact-form .col-xs-8, .contact-form .col-sm-8, .contact-form .col-md-8, .contact-form .col-lg-8, .contact-form .col-xs-9, .contact-form .col-sm-9, .contact-form .col-md-9, .contact-form .col-lg-9, .contact-form .col-xs-10, .contact-form .col-sm-10, .contact-form .col-md-10, .contact-form .col-lg-10, .contact-form .col-xs-11, .contact-form .col-sm-11, .contact-form .col-md-11, .contact-form .col-lg-11, .contact-form .col-xs-12, .contact-form .col-sm-12, .contact-form .col-md-12, .contact-form .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px; }
  .contact-form .col-xs-1, .contact-form .col-xs-2, .contact-form .col-xs-3, .contact-form .col-xs-4, .contact-form .col-xs-5, .contact-form .col-xs-6, .contact-form .col-xs-7, .contact-form .col-xs-8, .contact-form .col-xs-9, .contact-form .col-xs-10, .contact-form .col-xs-11, .contact-form .col-xs-12 {
    float: left; }
  .contact-form .col-xs-12 {
    width: 100%; }
  .contact-form .col-xs-11 {
    width: 91.66666667%; }
  .contact-form .col-xs-10 {
    width: 83.33333333%; }
  .contact-form .col-xs-9 {
    width: 75%; }
  .contact-form .col-xs-8 {
    width: 66.66666667%; }
  .contact-form .col-xs-7 {
    width: 58.33333333%; }
  .contact-form .col-xs-6 {
    width: 50%; }
  .contact-form .col-xs-5 {
    width: 41.66666667%; }
  .contact-form .col-xs-4 {
    width: 33.33333333%; }
  .contact-form .col-xs-3 {
    width: 25%; }
  .contact-form .col-xs-2 {
    width: 16.66666667%; }
  .contact-form .col-xs-1 {
    width: 8.33333333%; }
  .contact-form .col-xs-pull-12 {
    right: 100%; }
  .contact-form .col-xs-pull-11 {
    right: 91.66666667%; }
  .contact-form .col-xs-pull-10 {
    right: 83.33333333%; }
  .contact-form .col-xs-pull-9 {
    right: 75%; }
  .contact-form .col-xs-pull-8 {
    right: 66.66666667%; }
  .contact-form .col-xs-pull-7 {
    right: 58.33333333%; }
  .contact-form .col-xs-pull-6 {
    right: 50%; }
  .contact-form .col-xs-pull-5 {
    right: 41.66666667%; }
  .contact-form .col-xs-pull-4 {
    right: 33.33333333%; }
  .contact-form .col-xs-pull-3 {
    right: 25%; }
  .contact-form .col-xs-pull-2 {
    right: 16.66666667%; }
  .contact-form .col-xs-pull-1 {
    right: 8.33333333%; }
  .contact-form .col-xs-pull-0 {
    right: auto; }
  .contact-form .col-xs-push-12 {
    left: 100%; }
  .contact-form .col-xs-push-11 {
    left: 91.66666667%; }
  .contact-form .col-xs-push-10 {
    left: 83.33333333%; }
  .contact-form .col-xs-push-9 {
    left: 75%; }
  .contact-form .col-xs-push-8 {
    left: 66.66666667%; }
  .contact-form .col-xs-push-7 {
    left: 58.33333333%; }
  .contact-form .col-xs-push-6 {
    left: 50%; }
  .contact-form .col-xs-push-5 {
    left: 41.66666667%; }
  .contact-form .col-xs-push-4 {
    left: 33.33333333%; }
  .contact-form .col-xs-push-3 {
    left: 25%; }
  .contact-form .col-xs-push-2 {
    left: 16.66666667%; }
  .contact-form .col-xs-push-1 {
    left: 8.33333333%; }
  .contact-form .col-xs-push-0 {
    left: auto; }
  .contact-form .col-xs-offset-12 {
    margin-left: 100%; }
  .contact-form .col-xs-offset-11 {
    margin-left: 91.66666667%; }
  .contact-form .col-xs-offset-10 {
    margin-left: 83.33333333%; }
  .contact-form .col-xs-offset-9 {
    margin-left: 75%; }
  .contact-form .col-xs-offset-8 {
    margin-left: 66.66666667%; }
  .contact-form .col-xs-offset-7 {
    margin-left: 58.33333333%; }
  .contact-form .col-xs-offset-6 {
    margin-left: 50%; }
  .contact-form .col-xs-offset-5 {
    margin-left: 41.66666667%; }
  .contact-form .col-xs-offset-4 {
    margin-left: 33.33333333%; }
  .contact-form .col-xs-offset-3 {
    margin-left: 25%; }
  .contact-form .col-xs-offset-2 {
    margin-left: 16.66666667%; }
  .contact-form .col-xs-offset-1 {
    margin-left: 8.33333333%; }
  .contact-form .col-xs-offset-0 {
    margin-left: 0; }
  @media (min-width: 768px) {
    .contact-form .col-sm-1, .contact-form .col-sm-2, .contact-form .col-sm-3, .contact-form .col-sm-4, .contact-form .col-sm-5, .contact-form .col-sm-6, .contact-form .col-sm-7, .contact-form .col-sm-8, .contact-form .col-sm-9, .contact-form .col-sm-10, .contact-form .col-sm-11, .contact-form .col-sm-12 {
      float: left; }
    .contact-form .col-sm-12 {
      width: 100%; }
    .contact-form .col-sm-11 {
      width: 91.66666667%; }
    .contact-form .col-sm-10 {
      width: 83.33333333%; }
    .contact-form .col-sm-9 {
      width: 75%; }
    .contact-form .col-sm-8 {
      width: 66.66666667%; }
    .contact-form .col-sm-7 {
      width: 58.33333333%; }
    .contact-form .col-sm-6 {
      width: 50%; }
    .contact-form .col-sm-5 {
      width: 41.66666667%; }
    .contact-form .col-sm-4 {
      width: 33.33333333%; }
    .contact-form .col-sm-3 {
      width: 25%; }
    .contact-form .col-sm-2 {
      width: 16.66666667%; }
    .contact-form .col-sm-1 {
      width: 8.33333333%; }
    .contact-form .col-sm-pull-12 {
      right: 100%; }
    .contact-form .col-sm-pull-11 {
      right: 91.66666667%; }
    .contact-form .col-sm-pull-10 {
      right: 83.33333333%; }
    .contact-form .col-sm-pull-9 {
      right: 75%; }
    .contact-form .col-sm-pull-8 {
      right: 66.66666667%; }
    .contact-form .col-sm-pull-7 {
      right: 58.33333333%; }
    .contact-form .col-sm-pull-6 {
      right: 50%; }
    .contact-form .col-sm-pull-5 {
      right: 41.66666667%; }
    .contact-form .col-sm-pull-4 {
      right: 33.33333333%; }
    .contact-form .col-sm-pull-3 {
      right: 25%; }
    .contact-form .col-sm-pull-2 {
      right: 16.66666667%; }
    .contact-form .col-sm-pull-1 {
      right: 8.33333333%; }
    .contact-form .col-sm-pull-0 {
      right: auto; }
    .contact-form .col-sm-push-12 {
      left: 100%; }
    .contact-form .col-sm-push-11 {
      left: 91.66666667%; }
    .contact-form .col-sm-push-10 {
      left: 83.33333333%; }
    .contact-form .col-sm-push-9 {
      left: 75%; }
    .contact-form .col-sm-push-8 {
      left: 66.66666667%; }
    .contact-form .col-sm-push-7 {
      left: 58.33333333%; }
    .contact-form .col-sm-push-6 {
      left: 50%; }
    .contact-form .col-sm-push-5 {
      left: 41.66666667%; }
    .contact-form .col-sm-push-4 {
      left: 33.33333333%; }
    .contact-form .col-sm-push-3 {
      left: 25%; }
    .contact-form .col-sm-push-2 {
      left: 16.66666667%; }
    .contact-form .col-sm-push-1 {
      left: 8.33333333%; }
    .contact-form .col-sm-push-0 {
      left: auto; }
    .contact-form .col-sm-offset-12 {
      margin-left: 100%; }
    .contact-form .col-sm-offset-11 {
      margin-left: 91.66666667%; }
    .contact-form .col-sm-offset-10 {
      margin-left: 83.33333333%; }
    .contact-form .col-sm-offset-9 {
      margin-left: 75%; }
    .contact-form .col-sm-offset-8 {
      margin-left: 66.66666667%; }
    .contact-form .col-sm-offset-7 {
      margin-left: 58.33333333%; }
    .contact-form .col-sm-offset-6 {
      margin-left: 50%; }
    .contact-form .col-sm-offset-5 {
      margin-left: 41.66666667%; }
    .contact-form .col-sm-offset-4 {
      margin-left: 33.33333333%; }
    .contact-form .col-sm-offset-3 {
      margin-left: 25%; }
    .contact-form .col-sm-offset-2 {
      margin-left: 16.66666667%; }
    .contact-form .col-sm-offset-1 {
      margin-left: 8.33333333%; }
    .contact-form .col-sm-offset-0 {
      margin-left: 0; } }
  @media (min-width: 992px) {
    .contact-form .col-md-1, .contact-form .col-md-2, .contact-form .col-md-3, .contact-form .col-md-4, .contact-form .col-md-5, .contact-form .col-md-6, .contact-form .col-md-7, .contact-form .col-md-8, .contact-form .col-md-9, .contact-form .col-md-10, .contact-form .col-md-11, .contact-form .col-md-12 {
      float: left; }
    .contact-form .col-md-12 {
      width: 100%; }
    .contact-form .col-md-11 {
      width: 91.66666667%; }
    .contact-form .col-md-10 {
      width: 83.33333333%; }
    .contact-form .col-md-9 {
      width: 75%; }
    .contact-form .col-md-8 {
      width: 66.66666667%; }
    .contact-form .col-md-7 {
      width: 58.33333333%; }
    .contact-form .col-md-6 {
      width: 50%; }
    .contact-form .col-md-5 {
      width: 41.66666667%; }
    .contact-form .col-md-4 {
      width: 33.33333333%; }
    .contact-form .col-md-3 {
      width: 25%; }
    .contact-form .col-md-2 {
      width: 16.66666667%; }
    .contact-form .col-md-1 {
      width: 8.33333333%; }
    .contact-form .col-md-pull-12 {
      right: 100%; }
    .contact-form .col-md-pull-11 {
      right: 91.66666667%; }
    .contact-form .col-md-pull-10 {
      right: 83.33333333%; }
    .contact-form .col-md-pull-9 {
      right: 75%; }
    .contact-form .col-md-pull-8 {
      right: 66.66666667%; }
    .contact-form .col-md-pull-7 {
      right: 58.33333333%; }
    .contact-form .col-md-pull-6 {
      right: 50%; }
    .contact-form .col-md-pull-5 {
      right: 41.66666667%; }
    .contact-form .col-md-pull-4 {
      right: 33.33333333%; }
    .contact-form .col-md-pull-3 {
      right: 25%; }
    .contact-form .col-md-pull-2 {
      right: 16.66666667%; }
    .contact-form .col-md-pull-1 {
      right: 8.33333333%; }
    .contact-form .col-md-pull-0 {
      right: auto; }
    .contact-form .col-md-push-12 {
      left: 100%; }
    .contact-form .col-md-push-11 {
      left: 91.66666667%; }
    .contact-form .col-md-push-10 {
      left: 83.33333333%; }
    .contact-form .col-md-push-9 {
      left: 75%; }
    .contact-form .col-md-push-8 {
      left: 66.66666667%; }
    .contact-form .col-md-push-7 {
      left: 58.33333333%; }
    .contact-form .col-md-push-6 {
      left: 50%; }
    .contact-form .col-md-push-5 {
      left: 41.66666667%; }
    .contact-form .col-md-push-4 {
      left: 33.33333333%; }
    .contact-form .col-md-push-3 {
      left: 25%; }
    .contact-form .col-md-push-2 {
      left: 16.66666667%; }
    .contact-form .col-md-push-1 {
      left: 8.33333333%; }
    .contact-form .col-md-push-0 {
      left: auto; }
    .contact-form .col-md-offset-12 {
      margin-left: 100%; }
    .contact-form .col-md-offset-11 {
      margin-left: 91.66666667%; }
    .contact-form .col-md-offset-10 {
      margin-left: 83.33333333%; }
    .contact-form .col-md-offset-9 {
      margin-left: 75%; }
    .contact-form .col-md-offset-8 {
      margin-left: 66.66666667%; }
    .contact-form .col-md-offset-7 {
      margin-left: 58.33333333%; }
    .contact-form .col-md-offset-6 {
      margin-left: 50%; }
    .contact-form .col-md-offset-5 {
      margin-left: 41.66666667%; }
    .contact-form .col-md-offset-4 {
      margin-left: 33.33333333%; }
    .contact-form .col-md-offset-3 {
      margin-left: 25%; }
    .contact-form .col-md-offset-2 {
      margin-left: 16.66666667%; }
    .contact-form .col-md-offset-1 {
      margin-left: 8.33333333%; }
    .contact-form .col-md-offset-0 {
      margin-left: 0; } }
  @media (min-width: 1200px) {
    .contact-form .col-lg-1, .contact-form .col-lg-2, .contact-form .col-lg-3, .contact-form .col-lg-4, .contact-form .col-lg-5, .contact-form .col-lg-6, .contact-form .col-lg-7, .contact-form .col-lg-8, .contact-form .col-lg-9, .contact-form .col-lg-10, .contact-form .col-lg-11, .contact-form .col-lg-12 {
      float: left; }
    .contact-form .col-lg-12 {
      width: 100%; }
    .contact-form .col-lg-11 {
      width: 91.66666667%; }
    .contact-form .col-lg-10 {
      width: 83.33333333%; }
    .contact-form .col-lg-9 {
      width: 75%; }
    .contact-form .col-lg-8 {
      width: 66.66666667%; }
    .contact-form .col-lg-7 {
      width: 58.33333333%; }
    .contact-form .col-lg-6 {
      width: 50%; }
    .contact-form .col-lg-5 {
      width: 41.66666667%; }
    .contact-form .col-lg-4 {
      width: 33.33333333%; }
    .contact-form .col-lg-3 {
      width: 25%; }
    .contact-form .col-lg-2 {
      width: 16.66666667%; }
    .contact-form .col-lg-1 {
      width: 8.33333333%; }
    .contact-form .col-lg-pull-12 {
      right: 100%; }
    .contact-form .col-lg-pull-11 {
      right: 91.66666667%; }
    .contact-form .col-lg-pull-10 {
      right: 83.33333333%; }
    .contact-form .col-lg-pull-9 {
      right: 75%; }
    .contact-form .col-lg-pull-8 {
      right: 66.66666667%; }
    .contact-form .col-lg-pull-7 {
      right: 58.33333333%; }
    .contact-form .col-lg-pull-6 {
      right: 50%; }
    .contact-form .col-lg-pull-5 {
      right: 41.66666667%; }
    .contact-form .col-lg-pull-4 {
      right: 33.33333333%; }
    .contact-form .col-lg-pull-3 {
      right: 25%; }
    .contact-form .col-lg-pull-2 {
      right: 16.66666667%; }
    .contact-form .col-lg-pull-1 {
      right: 8.33333333%; }
    .contact-form .col-lg-pull-0 {
      right: auto; }
    .contact-form .col-lg-push-12 {
      left: 100%; }
    .contact-form .col-lg-push-11 {
      left: 91.66666667%; }
    .contact-form .col-lg-push-10 {
      left: 83.33333333%; }
    .contact-form .col-lg-push-9 {
      left: 75%; }
    .contact-form .col-lg-push-8 {
      left: 66.66666667%; }
    .contact-form .col-lg-push-7 {
      left: 58.33333333%; }
    .contact-form .col-lg-push-6 {
      left: 50%; }
    .contact-form .col-lg-push-5 {
      left: 41.66666667%; }
    .contact-form .col-lg-push-4 {
      left: 33.33333333%; }
    .contact-form .col-lg-push-3 {
      left: 25%; }
    .contact-form .col-lg-push-2 {
      left: 16.66666667%; }
    .contact-form .col-lg-push-1 {
      left: 8.33333333%; }
    .contact-form .col-lg-push-0 {
      left: auto; }
    .contact-form .col-lg-offset-12 {
      margin-left: 100%; }
    .contact-form .col-lg-offset-11 {
      margin-left: 91.66666667%; }
    .contact-form .col-lg-offset-10 {
      margin-left: 83.33333333%; }
    .contact-form .col-lg-offset-9 {
      margin-left: 75%; }
    .contact-form .col-lg-offset-8 {
      margin-left: 66.66666667%; }
    .contact-form .col-lg-offset-7 {
      margin-left: 58.33333333%; }
    .contact-form .col-lg-offset-6 {
      margin-left: 50%; }
    .contact-form .col-lg-offset-5 {
      margin-left: 41.66666667%; }
    .contact-form .col-lg-offset-4 {
      margin-left: 33.33333333%; }
    .contact-form .col-lg-offset-3 {
      margin-left: 25%; }
    .contact-form .col-lg-offset-2 {
      margin-left: 16.66666667%; }
    .contact-form .col-lg-offset-1 {
      margin-left: 8.33333333%; }
    .contact-form .col-lg-offset-0 {
      margin-left: 0; } }
  .contact-form fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0; }
  .contact-form legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5; }
  .contact-form label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold; }
  .contact-form .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
  .contact-form .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .contact-form .form-control::-moz-placeholder {
    color: #777;
    opacity: 1; }
  .contact-form .form-control:-ms-input-placeholder {
    color: #777; }
  .contact-form .form-control::-webkit-input-placeholder {
    color: #777; }
  .contact-form .form-control[disabled],
  .contact-form .form-control[readonly],
  .contact-form fieldset[disabled] .form-control {
    cursor: not-allowed;
    background-color: #eee;
    opacity: 1; }
  .contact-form textarea.form-control {
    height: auto; }
  .contact-form .form-group {
    margin-bottom: 15px; }
  .contact-form .form-control-static {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0; }
  .contact-form .form-control-static.input-lg,
  .contact-form .form-control-static.input-sm {
    padding-right: 0;
    padding-left: 0; }
  .contact-form .input-sm,
  .contact-form .form-horizontal .form-group-sm .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px; }
  .contact-form textarea.input-sm {
    height: auto; }
  .contact-form .input-lg,
  .contact-form .form-horizontal .form-group-lg .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px; }
  .contact-form textarea.input-lg {
    height: auto; }
  .contact-form .has-feedback {
    position: relative; }
  .contact-form .has-feedback .form-control {
    padding-right: 42.5px; }
  .contact-form .form-control-feedback {
    position: absolute;
    top: 25px;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center; }
  .contact-form .input-lg + .form-control-feedback {
    width: 46px;
    height: 46px;
    line-height: 46px; }
  .contact-form .input-sm + .form-control-feedback {
    width: 30px;
    height: 30px;
    line-height: 30px; }
  .contact-form .has-success .help-block,
  .contact-form .has-success .control-label,
  .contact-form .has-success .radio,
  .contact-form .has-success .checkbox,
  .contact-form .has-success .radio-inline,
  .contact-form .has-success .checkbox-inline {
    color: #3c763d; }
  .contact-form .has-success .form-control {
    border-color: #3c763d;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .contact-form .has-success .form-control:focus {
    border-color: #2b542c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168; }
  .contact-form .has-success .input-group-addon {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #3c763d; }
  .contact-form .has-success .form-control-feedback {
    color: #3c763d; }
  .contact-form .has-warning .help-block,
  .contact-form .has-warning .control-label,
  .contact-form .has-warning .radio,
  .contact-form .has-warning .checkbox,
  .contact-form .has-warning .radio-inline,
  .contact-form .has-warning .checkbox-inline {
    color: #8a6d3b; }
  .contact-form .has-warning .form-control {
    border-color: #8a6d3b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .contact-form .has-warning .form-control:focus {
    border-color: #66512c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b; }
  .contact-form .has-warning .input-group-addon {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #8a6d3b; }
  .contact-form .has-warning .form-control-feedback {
    color: #8a6d3b; }
  .contact-form .has-error .help-block,
  .contact-form .has-error .control-label,
  .contact-form .has-error .radio,
  .contact-form .has-error .checkbox,
  .contact-form .has-error .radio-inline,
  .contact-form .has-error .checkbox-inline {
    color: #a94442; }
  .contact-form .has-error .form-control {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .contact-form .has-error .form-control:focus {
    border-color: #843534;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }
  .contact-form .has-error .input-group-addon {
    color: #a94442;
    background-color: #f2dede;
    border-color: #a94442; }
  .contact-form .has-error .form-control-feedback {
    color: #a94442; }
  .contact-form .has-feedback label.sr-only ~ .form-control-feedback {
    top: 0; }
  .contact-form .help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373; }
  @media (min-width: 768px) {
    .contact-form .form-inline .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle; }
    .contact-form .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .contact-form .form-inline .input-group {
      display: inline-table;
      vertical-align: middle; }
    .contact-form .form-inline .input-group .input-group-addon,
    .contact-form .form-inline .input-group .input-group-btn,
    .contact-form .form-inline .input-group .form-control {
      width: auto; }
    .contact-form .form-inline .input-group > .form-control {
      width: 100%; }
    .contact-form .form-inline .control-label {
      margin-bottom: 0;
      vertical-align: middle; }
    .contact-form .form-inline .radio,
    .contact-form .form-inline .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle; }
    .contact-form .form-inline .radio label,
    .contact-form .form-inline .checkbox label {
      padding-left: 0; }
    .contact-form .form-inline .radio input[type="radio"],
    .contact-form .form-inline .checkbox input[type="checkbox"] {
      position: relative;
      margin-left: 0; }
    .contact-form .form-inline .has-feedback .form-control-feedback {
      top: 0; } }
  .contact-form .form-horizontal .radio,
  .contact-form .form-horizontal .checkbox,
  .contact-form .form-horizontal .radio-inline,
  .contact-form .form-horizontal .checkbox-inline {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0; }
  .contact-form .form-horizontal .radio,
  .contact-form .form-horizontal .checkbox {
    min-height: 27px; }
  .contact-form .form-horizontal .form-group {
    margin-right: -15px;
    margin-left: -15px; }
  @media (min-width: 768px) {
    .contact-form .form-horizontal .control-label {
      margin-bottom: 0;
      text-align: right; } }
  .contact-form .form-horizontal .has-feedback .form-control-feedback {
    top: 0;
    right: 15px; }
  @media (min-width: 768px) {
    .contact-form .form-horizontal .form-group-lg .control-label {
      padding-top: 14.3px; } }
  @media (min-width: 768px) {
    .contact-form .form-horizontal .form-group-sm .control-label {
      padding-top: 6px; } }
  .contact-form .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px; }
  .contact-form .btn:focus,
  .contact-form .btn:active:focus,
  .contact-form .btn.active:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .contact-form .btn:hover,
  .contact-form .btn:focus {
    color: #333;
    text-decoration: none; }
  .contact-form .btn:active,
  .contact-form .btn.active {
    background-image: none;
    outline: 0;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .contact-form .btn.disabled,
  .contact-form .btn[disabled],
  .contact-form fieldset[disabled] .btn {
    pointer-events: none;
    cursor: not-allowed;
    filter: alpha(opacity=65);
    box-shadow: none;
    opacity: .65; }
  .contact-form .btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc; }
  .contact-form .btn-default:hover,
  .contact-form .btn-default:focus,
  .contact-form .btn-default:active,
  .contact-form .btn-default.active,
  .contact-form .open > .dropdown-toggle.btn-default {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad; }
  .contact-form .btn-default:active,
  .contact-form .btn-default.active,
  .contact-form .open > .dropdown-toggle.btn-default {
    background-image: none; }
  .contact-form .btn-default.disabled,
  .contact-form .btn-default[disabled],
  .contact-form fieldset[disabled] .btn-default,
  .contact-form .btn-default.disabled:hover,
  .contact-form .btn-default[disabled]:hover,
  .contact-form fieldset[disabled] .btn-default:hover,
  .contact-form .btn-default.disabled:focus,
  .contact-form .btn-default[disabled]:focus,
  .contact-form fieldset[disabled] .btn-default:focus,
  .contact-form .btn-default.disabled:active,
  .contact-form .btn-default[disabled]:active,
  .contact-form fieldset[disabled] .btn-default:active,
  .contact-form .btn-default.disabled.active,
  .contact-form .btn-default[disabled].active,
  .contact-form fieldset[disabled] .btn-default.active {
    background-color: #fff;
    border-color: #ccc; }
  .contact-form .btn-default .badge {
    color: #fff;
    background-color: #333; }
  .contact-form .btn-primary {
    color: #fff;
    background-color: #708e68;
    border-color: #708e68; }
  .contact-form .btn-primary:hover,
  .contact-form .btn-primary:focus,
  .contact-form .btn-primary:active,
  .contact-form .btn-primary.active,
  .contact-form .open > .dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #708e68;
    border-color: #708e68;
    outline: 0; }
  .contact-form .btn-primary:active,
  .contact-form .btn-primary.active,
  .contact-form .open > .dropdown-toggle.btn-primary {
    background-image: none; }
  .contact-form .btn-primary.disabled,
  .contact-form .btn-primary[disabled],
  .contact-form fieldset[disabled] .btn-primary,
  .contact-form .btn-primary.disabled:hover,
  .contact-form .btn-primary[disabled]:hover,
  .contact-form fieldset[disabled] .btn-primary:hover,
  .contact-form .btn-primary.disabled:focus,
  .contact-form .btn-primary[disabled]:focus,
  .contact-form fieldset[disabled] .btn-primary:focus,
  .contact-form .btn-primary.disabled:active,
  .contact-form .btn-primary[disabled]:active,
  .contact-form fieldset[disabled] .btn-primary:active,
  .contact-form .btn-primary.disabled.active,
  .contact-form .btn-primary[disabled].active,
  .contact-form fieldset[disabled] .btn-primary.active {
    background-color: #708e68;
    border-color: #708e68; }
  .contact-form .btn-primary .badge {
    color: #428bca;
    background-color: #fff; }
  .contact-form .btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c; }
  .contact-form .btn-success:hover,
  .contact-form .btn-success:focus,
  .contact-form .btn-success:active,
  .contact-form .btn-success.active,
  .contact-form .open > .dropdown-toggle.btn-success {
    color: #fff;
    background-color: #449d44;
    border-color: #398439; }
  .contact-form .btn-success:active,
  .contact-form .btn-success.active,
  .contact-form .open > .dropdown-toggle.btn-success {
    background-image: none; }
  .contact-form .btn-success.disabled,
  .contact-form .btn-success[disabled],
  .contact-form fieldset[disabled] .btn-success,
  .contact-form .btn-success.disabled:hover,
  .contact-form .btn-success[disabled]:hover,
  .contact-form fieldset[disabled] .btn-success:hover,
  .contact-form .btn-success.disabled:focus,
  .contact-form .btn-success[disabled]:focus,
  .contact-form fieldset[disabled] .btn-success:focus,
  .contact-form .btn-success.disabled:active,
  .contact-form .btn-success[disabled]:active,
  .contact-form fieldset[disabled] .btn-success:active,
  .contact-form .btn-success.disabled.active,
  .contact-form .btn-success[disabled].active,
  .contact-form fieldset[disabled] .btn-success.active {
    background-color: #5cb85c;
    border-color: #4cae4c; }
  .contact-form .btn-success .badge {
    color: #5cb85c;
    background-color: #fff; }
  .contact-form .btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da; }
  .contact-form .btn-info:hover,
  .contact-form .btn-info:focus,
  .contact-form .btn-info:active,
  .contact-form .btn-info.active,
  .contact-form .open > .dropdown-toggle.btn-info {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc; }
  .contact-form .btn-info:active,
  .contact-form .btn-info.active,
  .contact-form .open > .dropdown-toggle.btn-info {
    background-image: none; }
  .contact-form .btn-info.disabled,
  .contact-form .btn-info[disabled],
  .contact-form fieldset[disabled] .btn-info,
  .contact-form .btn-info.disabled:hover,
  .contact-form .btn-info[disabled]:hover,
  .contact-form fieldset[disabled] .btn-info:hover,
  .contact-form .btn-info.disabled:focus,
  .contact-form .btn-info[disabled]:focus,
  .contact-form fieldset[disabled] .btn-info:focus,
  .contact-form .btn-info.disabled:active,
  .contact-form .btn-info[disabled]:active,
  .contact-form fieldset[disabled] .btn-info:active,
  .contact-form .btn-info.disabled.active,
  .contact-form .btn-info[disabled].active,
  .contact-form fieldset[disabled] .btn-info.active {
    background-color: #5bc0de;
    border-color: #46b8da; }
  .contact-form .btn-info .badge {
    color: #5bc0de;
    background-color: #fff; }
  .contact-form .btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236; }
  .contact-form .btn-warning:hover,
  .contact-form .btn-warning:focus,
  .contact-form .btn-warning:active,
  .contact-form .btn-warning.active,
  .contact-form .open > .dropdown-toggle.btn-warning {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512; }
  .contact-form .btn-warning:active,
  .contact-form .btn-warning.active,
  .contact-form .open > .dropdown-toggle.btn-warning {
    background-image: none; }
  .contact-form .btn-warning.disabled,
  .contact-form .btn-warning[disabled],
  .contact-form fieldset[disabled] .btn-warning,
  .contact-form .btn-warning.disabled:hover,
  .contact-form .btn-warning[disabled]:hover,
  .contact-form fieldset[disabled] .btn-warning:hover,
  .contact-form .btn-warning.disabled:focus,
  .contact-form .btn-warning[disabled]:focus,
  .contact-form fieldset[disabled] .btn-warning:focus,
  .contact-form .btn-warning.disabled:active,
  .contact-form .btn-warning[disabled]:active,
  .contact-form fieldset[disabled] .btn-warning:active,
  .contact-form .btn-warning.disabled.active,
  .contact-form .btn-warning[disabled].active,
  .contact-form fieldset[disabled] .btn-warning.active {
    background-color: #f0ad4e;
    border-color: #eea236; }
  .contact-form .btn-warning .badge {
    color: #f0ad4e;
    background-color: #fff; }
  .contact-form .btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a; }
  .contact-form .btn-danger:hover,
  .contact-form .btn-danger:focus,
  .contact-form .btn-danger:active,
  .contact-form .btn-danger.active,
  .contact-form .open > .dropdown-toggle.btn-danger {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925; }
  .contact-form .btn-danger:active,
  .contact-form .btn-danger.active,
  .contact-form .open > .dropdown-toggle.btn-danger {
    background-image: none; }
  .contact-form .btn-danger.disabled,
  .contact-form .btn-danger[disabled],
  .contact-form fieldset[disabled] .btn-danger,
  .contact-form .btn-danger.disabled:hover,
  .contact-form .btn-danger[disabled]:hover,
  .contact-form fieldset[disabled] .btn-danger:hover,
  .contact-form .btn-danger.disabled:focus,
  .contact-form .btn-danger[disabled]:focus,
  .contact-form fieldset[disabled] .btn-danger:focus,
  .contact-form .btn-danger.disabled:active,
  .contact-form .btn-danger[disabled]:active,
  .contact-form fieldset[disabled] .btn-danger:active,
  .contact-form .btn-danger.disabled.active,
  .contact-form .btn-danger[disabled].active,
  .contact-form fieldset[disabled] .btn-danger.active {
    background-color: #d9534f;
    border-color: #d43f3a; }
  .contact-form .btn-danger .badge {
    color: #d9534f;
    background-color: #fff; }
  .contact-form .btn-link {
    font-weight: normal;
    color: #428bca;
    cursor: pointer;
    border-radius: 0; }
  .contact-form .btn-link,
  .contact-form .btn-link:active,
  .contact-form .btn-link[disabled],
  .contact-form fieldset[disabled] .btn-link {
    background-color: transparent;
    box-shadow: none; }
  .contact-form .btn-link,
  .contact-form .btn-link:hover,
  .contact-form .btn-link:focus,
  .contact-form .btn-link:active {
    border-color: transparent; }
  .contact-form .btn-link:hover,
  .contact-form .btn-link:focus {
    color: #2a6496;
    text-decoration: underline;
    background-color: transparent; }
  .contact-form .btn-link[disabled]:hover,
  .contact-form fieldset[disabled] .btn-link:hover,
  .contact-form .btn-link[disabled]:focus,
  .contact-form fieldset[disabled] .btn-link:focus {
    color: #777;
    text-decoration: none; }
  .contact-form .btn-lg,
  .contact-form .btn-group-lg > .btn {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px; }
  .contact-form .btn-sm,
  .contact-form .btn-group-sm > .btn {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px; }
  .contact-form .btn-xs,
  .contact-form .btn-group-xs > .btn {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px; }
  .contact-form .btn-block {
    display: block;
    width: 100%; }
  .contact-form .btn-block + .btn-block {
    margin-top: 5px; }
  .contact-form input[type="submit"].btn-block,
  .contact-form input[type="reset"].btn-block,
  .contact-form input[type="button"].btn-block {
    width: 100%; }
  .contact-form .fade {
    opacity: 0;
    transition: opacity .15s linear; }
  .contact-form .fade.in {
    opacity: 1; }
  .contact-form .collapse {
    display: none; }
  .contact-form .collapse.in {
    display: block; }
  .contact-form tr.collapse.in {
    display: table-row; }
  .contact-form tbody.collapse.in {
    display: table-row-group; }
  .contact-form .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease; }
  .contact-form .btn-group,
  .contact-form .btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle; }
  .contact-form .btn-group > .btn,
  .contact-form .btn-group-vertical > .btn {
    position: relative;
    float: left; }
  .contact-form .btn-group > .btn:hover,
  .contact-form .btn-group-vertical > .btn:hover,
  .contact-form .btn-group > .btn:focus,
  .contact-form .btn-group-vertical > .btn:focus,
  .contact-form .btn-group > .btn:active,
  .contact-form .btn-group-vertical > .btn:active,
  .contact-form .btn-group > .btn.active,
  .contact-form .btn-group-vertical > .btn.active {
    z-index: 2; }
  .contact-form .btn-group > .btn:focus,
  .contact-form .btn-group-vertical > .btn:focus {
    outline: 0; }
  .contact-form .btn-group .btn + .btn,
  .contact-form .btn-group .btn + .btn-group,
  .contact-form .btn-group .btn-group + .btn,
  .contact-form .btn-group .btn-group + .btn-group {
    margin-left: -1px; }
  .contact-form .btn-toolbar {
    margin-left: -5px; }
  .contact-form .btn-toolbar .btn-group,
  .contact-form .btn-toolbar .input-group {
    float: left; }
  .contact-form .btn-toolbar > .btn,
  .contact-form .btn-toolbar > .btn-group,
  .contact-form .btn-toolbar > .input-group {
    margin-left: 5px; }
  .contact-form .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0; }
  .contact-form .btn-group > .btn:first-child {
    margin-left: 0; }
  .contact-form .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .contact-form .btn-group > .btn:last-child:not(:first-child),
  .contact-form .btn-group > .dropdown-toggle:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .contact-form .btn-group > .btn-group {
    float: left; }
  .contact-form .btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
    border-radius: 0; }
  .contact-form .btn-group > .btn-group:first-child > .btn:last-child,
  .contact-form .btn-group > .btn-group:first-child > .dropdown-toggle {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .contact-form .btn-group > .btn-group:last-child > .btn:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .contact-form .btn-group .dropdown-toggle:active,
  .contact-form .btn-group.open .dropdown-toggle {
    outline: 0; }
  .contact-form .btn-group > .btn + .dropdown-toggle {
    padding-right: 8px;
    padding-left: 8px; }
  .contact-form .btn-group > .btn-lg + .dropdown-toggle {
    padding-right: 12px;
    padding-left: 12px; }
  .contact-form .btn-group.open .dropdown-toggle {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .contact-form .btn-group.open .dropdown-toggle.btn-link {
    box-shadow: none; }
  .contact-form .btn .caret {
    margin-left: 0; }
  .contact-form .btn-lg .caret {
    border-width: 5px 5px 0;
    border-bottom-width: 0; }
  .contact-form .dropup .btn-lg .caret {
    border-width: 0 5px 5px; }
  .contact-form .btn-group-vertical > .btn,
  .contact-form .btn-group-vertical > .btn-group,
  .contact-form .btn-group-vertical > .btn-group > .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%; }
  .contact-form .btn-group-vertical > .btn-group > .btn {
    float: none; }
  .contact-form .btn-group-vertical > .btn + .btn,
  .contact-form .btn-group-vertical > .btn + .btn-group,
  .contact-form .btn-group-vertical > .btn-group + .btn,
  .contact-form .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .contact-form .btn-group-vertical > .btn:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .contact-form .btn-group-vertical > .btn:first-child:not(:last-child) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .contact-form .btn-group-vertical > .btn:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px; }
  .contact-form .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
    border-radius: 0; }
  .contact-form .btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
  .contact-form .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .contact-form .btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .contact-form .btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate; }
  .contact-form .btn-group-justified > .btn,
  .contact-form .btn-group-justified > .btn-group {
    display: table-cell;
    float: none;
    width: 1%; }
  .contact-form .btn-group-justified > .btn-group .btn {
    width: 100%; }
  .contact-form .btn-group-justified > .btn-group .dropdown-menu {
    left: auto; }
  .contact-form [data-toggle="buttons"] > .btn > input[type="radio"],
  .contact-form [data-toggle="buttons"] > .btn > input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    filter: alpha(opacity=0);
    opacity: 0; }
  .contact-form .input-group {
    position: relative;
    display: table;
    border-collapse: separate; }
  .contact-form .input-group[class*="col-"] {
    float: none;
    padding-right: 0;
    padding-left: 0; }
  .contact-form .input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0; }
  .contact-form .input-group-lg > .form-control,
  .contact-form .input-group-lg > .input-group-addon,
  .contact-form .input-group-lg > .input-group-btn > .btn {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px; }
  .contact-form select.input-group-lg > .form-control,
  .contact-form select.input-group-lg > .input-group-addon,
  .contact-form select.input-group-lg > .input-group-btn > .btn {
    height: 46px;
    line-height: 46px; }
  .contact-form textarea.input-group-lg > .form-control,
  .contact-form textarea.input-group-lg > .input-group-addon,
  .contact-form textarea.input-group-lg > .input-group-btn > .btn,
  .contact-form select[multiple].input-group-lg > .form-control,
  .contact-form select[multiple].input-group-lg > .input-group-addon,
  .contact-form select[multiple].input-group-lg > .input-group-btn > .btn {
    height: auto; }
  .contact-form .input-group-sm > .form-control,
  .contact-form .input-group-sm > .input-group-addon,
  .contact-form .input-group-sm > .input-group-btn > .btn {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px; }
  .contact-form select.input-group-sm > .form-control,
  .contact-form select.input-group-sm > .input-group-addon,
  .contact-form select.input-group-sm > .input-group-btn > .btn {
    height: 30px;
    line-height: 30px; }
  .contact-form textarea.input-group-sm > .form-control,
  .contact-form textarea.input-group-sm > .input-group-addon,
  .contact-form textarea.input-group-sm > .input-group-btn > .btn,
  .contact-form select[multiple].input-group-sm > .form-control,
  .contact-form select[multiple].input-group-sm > .input-group-addon,
  .contact-form select[multiple].input-group-sm > .input-group-btn > .btn {
    height: auto; }
  .contact-form .input-group-addon,
  .contact-form .input-group-btn,
  .contact-form .input-group .form-control {
    display: table-cell; }
  .contact-form .input-group-addon:not(:first-child):not(:last-child),
  .contact-form .input-group-btn:not(:first-child):not(:last-child),
  .contact-form .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .contact-form .input-group-addon,
  .contact-form .input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle; }
  .contact-form .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px; }
  .contact-form .input-group-addon.input-sm {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px; }
  .contact-form .input-group-addon.input-lg {
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 6px; }
  .contact-form .input-group-addon input[type="radio"],
  .contact-form .input-group-addon input[type="checkbox"] {
    margin-top: 0; }
  .contact-form .input-group .form-control:first-child,
  .contact-form .input-group-addon:first-child,
  .contact-form .input-group-btn:first-child > .btn,
  .contact-form .input-group-btn:first-child > .btn-group > .btn,
  .contact-form .input-group-btn:first-child > .dropdown-toggle,
  .contact-form .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .contact-form .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .contact-form .input-group-addon:first-child {
    border-right: 0; }
  .contact-form .input-group .form-control:last-child,
  .contact-form .input-group-addon:last-child,
  .contact-form .input-group-btn:last-child > .btn,
  .contact-form .input-group-btn:last-child > .btn-group > .btn,
  .contact-form .input-group-btn:last-child > .dropdown-toggle,
  .contact-form .input-group-btn:first-child > .btn:not(:first-child),
  .contact-form .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .contact-form .input-group-addon:last-child {
    border-left: 0; }
  .contact-form .input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap; }
  .contact-form .input-group-btn > .btn {
    position: relative; }
  .contact-form .input-group-btn > .btn + .btn {
    margin-left: -1px; }
  .contact-form .input-group-btn > .btn:hover,
  .contact-form .input-group-btn > .btn:focus,
  .contact-form .input-group-btn > .btn:active {
    z-index: 2; }
  .contact-form .input-group-btn:first-child > .btn,
  .contact-form .input-group-btn:first-child > .btn-group {
    margin-right: -1px; }
  .contact-form .input-group-btn:last-child > .btn,
  .contact-form .input-group-btn:last-child > .btn-group {
    margin-left: -1px; }
  .contact-form .label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em; }
  .contact-form a.label:hover,
  .contact-form a.label:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer; }
  .contact-form .label:empty {
    display: none; }
  .contact-form .btn .label {
    position: relative;
    top: -1px; }
  .contact-form .label-default {
    background-color: #777; }
  .contact-form .label-default[href]:hover,
  .contact-form .label-default[href]:focus {
    background-color: #5e5e5e; }
  .contact-form .label-primary {
    background-color: #428bca; }
  .contact-form .label-primary[href]:hover,
  .contact-form .label-primary[href]:focus {
    background-color: #3071a9; }
  .contact-form .label-success {
    background-color: #5cb85c; }
  .contact-form .label-success[href]:hover,
  .contact-form .label-success[href]:focus {
    background-color: #449d44; }
  .contact-form .label-info {
    background-color: #5bc0de; }
  .contact-form .label-info[href]:hover,
  .contact-form .label-info[href]:focus {
    background-color: #31b0d5; }
  .contact-form .label-warning {
    background-color: #f0ad4e; }
  .contact-form .label-warning[href]:hover,
  .contact-form .label-warning[href]:focus {
    background-color: #ec971f; }
  .contact-form .label-danger {
    background-color: #d9534f; }
  .contact-form .label-danger[href]:hover,
  .contact-form .label-danger[href]:focus {
    background-color: #c9302c; }
  .contact-form .badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: #777;
    border-radius: 10px; }
  .contact-form .badge:empty {
    display: none; }
  .contact-form .btn .badge {
    position: relative;
    top: -1px; }
  .contact-form .btn-xs .badge {
    top: 0;
    padding: 1px 5px; }
  .contact-form a.badge:hover,
  .contact-form a.badge:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer; }
  .contact-form a.list-group-item.active > .badge,
  .contact-form .nav-pills > .active > a > .badge {
    color: #428bca;
    background-color: #fff; }
  .contact-form .nav-pills > li > a > .badge {
    margin-left: 3px; }
  .contact-form .alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px; }
  .contact-form .alert h4 {
    margin-top: 0;
    color: inherit; }
  .contact-form .alert .alert-link {
    font-weight: bold; }
  .contact-form .alert > p,
  .contact-form .alert > ul {
    margin-bottom: 0; }
  .contact-form .alert > p + p {
    margin-top: 5px; }
  .contact-form .alert-dismissable,
  .contact-form .alert-dismissible {
    padding-right: 35px; }
  .contact-form .alert-dismissable .close,
  .contact-form .alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit; }
  .contact-form .alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6; }
  .contact-form .alert-success hr {
    border-top-color: #c9e2b3; }
  .contact-form .alert-success .alert-link {
    color: #2b542c; }
  .contact-form .alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1; }
  .contact-form .alert-info hr {
    border-top-color: #a6e1ec; }
  .contact-form .alert-info .alert-link {
    color: #245269; }
  .contact-form .alert-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc; }
  .contact-form .alert-warning hr {
    border-top-color: #f7e1b5; }
  .contact-form .alert-warning .alert-link {
    color: #66512c; }
  .contact-form .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1; }
  .contact-form .alert-danger hr {
    border-top-color: #e4b9c0; }
  .contact-form .alert-danger .alert-link {
    color: #843534; }
  .contact-form .embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden; }
  .contact-form .embed-responsive .embed-responsive-item,
  .contact-form .embed-responsive iframe,
  .contact-form .embed-responsive embed,
  .contact-form .embed-responsive object {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }
  .contact-form .embed-responsive.embed-responsive-16by9 {
    padding-bottom: 56.25%; }
  .contact-form .embed-responsive.embed-responsive-4by3 {
    padding-bottom: 75%; }
  .contact-form .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }
  .contact-form .well blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15); }
  .contact-form .well-lg {
    padding: 24px;
    border-radius: 6px; }
  .contact-form .well-sm {
    padding: 9px;
    border-radius: 3px; }
  .contact-form .close {
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2; }
  .contact-form .close:hover,
  .contact-form .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: .5; }
  .contact-form button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0; }
  .contact-form .clearfix:before,
  .contact-form .clearfix:after,
  .contact-form .dl-horizontal dd:before,
  .contact-form .dl-horizontal dd:after,
  .contact-form .container:before,
  .contact-form .container:after,
  .contact-form .container-fluid:before,
  .contact-form .container-fluid:after,
  .contact-form .row:before,
  .contact-form .row:after,
  .contact-form .form-horizontal .form-group:before,
  .contact-form .form-horizontal .form-group:after,
  .contact-form .btn-toolbar:before,
  .contact-form .btn-toolbar:after,
  .contact-form .btn-group-vertical > .btn-group:before,
  .contact-form .btn-group-vertical > .btn-group:after,
  .contact-form .nav:before,
  .contact-form .nav:after,
  .contact-form .navbar:before,
  .contact-form .navbar:after,
  .contact-form .navbar-header:before,
  .contact-form .navbar-header:after,
  .contact-form .navbar-collapse:before,
  .contact-form .navbar-collapse:after,
  .contact-form .pager:before,
  .contact-form .pager:after,
  .contact-form .panel-body:before,
  .contact-form .panel-body:after,
  .contact-form .modal-footer:before,
  .contact-form .modal-footer:after {
    display: table;
    content: " "; }
  .contact-form .clearfix:after,
  .contact-form .dl-horizontal dd:after,
  .contact-form .container:after,
  .contact-form .container-fluid:after,
  .contact-form .row:after,
  .contact-form .form-horizontal .form-group:after,
  .contact-form .btn-toolbar:after,
  .contact-form .btn-group-vertical > .btn-group:after,
  .contact-form .nav:after,
  .contact-form .navbar:after,
  .contact-form .navbar-header:after,
  .contact-form .navbar-collapse:after,
  .contact-form .pager:after,
  .contact-form .panel-body:after,
  .contact-form .modal-footer:after {
    clear: both; }
  .contact-form .center-block {
    display: block;
    margin-right: auto;
    margin-left: auto; }
  .contact-form .pull-right {
    float: right !important; }
  .contact-form .pull-left {
    float: left !important; }
  .contact-form .bv-form .help-block {
    margin-bottom: 0; }
  .contact-form .bv-form .tooltip-inner {
    text-align: left; }
  .contact-form .nav-tabs li.bv-tab-success > a {
    color: #3c763d; }
  .contact-form .nav-tabs li.bv-tab-error > a {
    color: #a94442; }
  .contact-form .bv-form .bv-icon-no-label {
    top: 0; }
  .contact-form .bv-form .bv-icon-input-group {
    top: 0;
    z-index: 100; }
  .contact-form .bottom-align-text {
    position: absolute;
    bottom: 0;
    right: 0; }
  .contact-form label {
    font-size: 0.875rem; }